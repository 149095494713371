import PropTypes from "prop-types";
import dynamic from "next/dynamic";
import { useInView } from "react-intersection-observer";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";

import {
    MobileSlider,
    MobileStep,
    Step,
    List,
    Title,
    Text
} from "./StepList.styled";

const Slider = dynamic(() => import("components/ui/slider/Slider"));

function StepList({ items }) {
    const { mobileView } = useMediaQueryContext();
    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: true
    });

    if (!items || items?.length === 0) {
        return null;
    }

    return (
        <>
            <MobileSlider ref={ref}>
                {mobileView && inView && (
                    <Slider
                        overflowVisible
                        navigationDesktop={false}
                        navigationMobile={false}
                        items={items.map(({ title, description }, index) => (
                            <MobileStep key={`step-${index}-${title}`}>
                                {title && <Title>{title}</Title>}
                                {description && <Text>{description}</Text>}
                            </MobileStep>
                        ))}
                    />
                )}
            </MobileSlider>
            {!mobileView && (
                <List>
                    {items.map(({ title, description }, index) => (
                        <Step key={`step-${index}-${title}`}>
                            <Title>{title}</Title>
                            {description && <Text>{description}</Text>}
                        </Step>
                    ))}
                </List>
            )}
        </>
    );
}

StepList.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string
        })
    )
};

StepList.defaultProps = {
    items: []
};

export default StepList;
