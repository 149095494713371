import styled, { css } from "styled-components";

import media from "constants/media";

export const Container = styled.div`
    position: relative;

    margin: 20px 0 0;

    ${media.mdUp} {
        flex: 1 0 auto;
        max-width: ${({ isPrivate }) => (isPrivate ? "420px" : "375px")};
        margin: 0;
    }

    ${({ isPrivate }) =>
        !isPrivate &&
        css`
            ${media.lgUp} {
                max-width: 365px;
            }

            ${media.xlUp} {
                max-width: 445px;
            }
        `}
`;
