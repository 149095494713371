import styled, { css } from "styled-components";

import media from "constants/media";

import Button from "components/ui/button/Button";
import BaseContainer from "components/ui/container/Container";
import ContentRenderer from "components/ui/contentRenderer/ContentRenderer";

export const Wrapper = styled(BaseContainer)`
    margin: 45px auto 55px;

    ${media.mdUp} {
        margin: 0 auto 85px;
    }
`;

export const IntroRow = styled.div`
    display: flex;
    flex-direction: column-reverse;

    ${media.mdUp} {
        flex-direction: row;
    }
`;

export const Intro = styled.div`
    z-index: 2;

    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0 -10px;
    padding: 100px 10px 0;

    background-color: var(--color-white);

    ${media.mdUp} {
        min-width: 320px;
        margin: 0;
        padding: ${({ isPrivate }) =>
            isPrivate ? "0 40px 40px 0" : "0 30px 30px 0"};

        background-color: transparent;
    }

    ${media.lgUp} {
        min-width: 414px;
        padding: ${({ isPrivate }) =>
            isPrivate ? "0 50px 50px 0" : "0 20px 30px 0"};
    }

    ${media.xlUp} {
        min-width: 500px;
        padding: ${({ isPrivate }) =>
            isPrivate ? "50px 50px 50px 55px" : "30px 50px 55px 55px"};
    }
`;

export const Title = styled.h2`
    margin: 0 0 10px;

    font-size: 28px;
    line-height: 40px;

    ${media.lgUp} {
        font-size: 40px;
        line-height: 55px;
    }
`;

export const Description = styled(ContentRenderer)`
    margin: 0;

    ${media.mdUp} {
        margin: 0 0 20px;
    }
`;

export const StyledButton = styled(Button)`
    ${({ isMobile }) =>
        isMobile &&
        css`
            ${media.mobileOnly}
        `}

    ${media.mdUp} {
        margin: 0 auto 0 0;
    }
`;

export const SearchRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px 0;
    margin: 0 -10px;
    padding: 0 10px 40px;

    background-color: var(--color-white);

    ${media.mdUp} {
        flex-direction: row;
        gap: 0 20px;
        justify-content: space-between;
        max-width: ${({ isPrivate }) => (isPrivate ? "90%" : "initial")};
        margin: 0;
        padding: ${({ isPrivate }) =>
            isPrivate ? "30px 30px 50px 30px" : "30px"};

        border-radius: var(--border-radius-medium);
    }

    ${media.lgUp} {
        gap: 0 40px;
        max-width: 900px;
        margin: ${({ isPrivate }) => (isPrivate ? "0" : "-10px 0 0")};
        padding: ${({ isPrivate }) => (isPrivate ? "50px 50px 85px" : "50px")};
    }

    ${media.xlUp} {
        max-width: 1080px;

        ${({ isPrivate }) =>
            !isPrivate &&
            css`
                margin: -20px 0 0;
            `}
    }
`;

const ImageBackground = css`
    ::after {
        position: absolute;
        top: -15px;
        right: -130px;
        z-index: 1;

        display: block;
        width: 310px;
        height: 70px;

        background-image: url("/images/illustrations/waves.svg");
        background-repeat: no-repeat;
        background-position: top;
        background-size: 100%;

        content: "";

        ${media.mdUp} {
            top: -40px;
            right: -40px;
        }

        ${media.xlUp} {
            top: -50px;
            right: -150px;

            width: 550px;
            height: 124px;
        }
    }
`;

export const ImageContainer = styled.div`
    position: relative;
    z-index: 2;

    width: 90%;
    max-width: 480px;
    height: ${({ isPrivate }) => (isPrivate ? "115px" : "auto")};
    margin: 0 0 -70px -30px;
    padding-top: 20px;

    ${({ isPrivate }) =>
        isPrivate &&
        css`
            img {
                border-bottom-right-radius: 50px;
            }
        `}

    /* stylelint-disable-next-line */
    ${media.mdUp} {
        max-width: unset;
        padding-top: 0;

        ${({ isPrivate }) =>
            isPrivate
                ? css`
                      width: 240px;
                      height: 310px;
                      margin: 0 0 -380px;
                  `
                : css`
                      width: 680px;
                      margin: 0;
                  `}

        img {
            border-bottom-right-radius: 100px;
        }
    }

    ${({ isPrivate }) =>
        isPrivate &&
        css`
            ${media.lgUp} {
                width: 440px;
                height: 380px;
            }
        `}

    ${media.xlUp} {
        width: 680px;
        height: 310px;

        ${({ isPrivate }) =>
            isPrivate
                ? css`
                      width: 580px;
                      height: 500px;
                      margin: 0 0 -380px;
                  `
                : css`
                      width: 680px;
                      height: 310px;
                  `}
    }

    ${ImageBackground}
`;
