import styled from "styled-components";

import media from "constants/media";

import BaseContainer from "components/ui/container/Container";
import ContentRenderer from "components/ui/contentRenderer/ContentRenderer";

export const Container = styled(BaseContainer)`
    ${media.mdUp} {
        margin: 0 auto 50px;
    }

    ${media.lgUp} {
        display: flex;
        gap: 0 20px;
        justify-content: space-between;
    }
`;

export const Content = styled.div`
    margin-bottom: 70px;

    ${media.mdUp} {
        margin-bottom: 20px;
    }

    ${media.lgUp} {
        width: 50%;
        margin-bottom: 0;
        padding: 45px;

        background-color: var(--color-white);
    }
`;

export const Title = styled.h2`
    margin: 0 0 10px;

    font-size: 26px;
    line-height: 36px;

    ${media.mdUp} {
        margin: 0 0 20px;

        font-size: 30px;
        line-height: 40px;
    }
`;

export const Text = styled(ContentRenderer)`
    margin: 0;
`;

export const ImageContainer = styled.div`
    ${media.lgUp} {
        width: 50%;
    }
`;
