import styled from "styled-components";
import NextImage from "next/image";

import media from "constants/media";
import { hideScrollbar } from "theme/mixins";

import { skeleton } from "components/ui/skeleton/Skeleton";

export const LogoList = styled.ul`
    display: flex;
    flex-direction: row;
    gap: 0 20px;
    align-items: center;
    width: 100vw;
    height: 60px;
    margin: 0;
    padding: 0;
    overflow-x: scroll;

    list-style: none;
    transform: translateX(${({ inView }) => (inView ? 0 : 50)}%);
    scroll-snap-type: x mandatory;
    scroll-padding: 50%;

    transition: transform var(--animation-duration-long) ease;

    ${hideScrollbar}

    ${media.mdUp} {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;

        width: initial;
        height: initial;
        overflow-x: initial;

        transform: none;
        scroll-snap-type: none;
        scroll-padding: unset;
    }

    ${media.xlUp} {
        gap: 35px;
    }
`;

export const ListItem = styled.li`
    scroll-snap-align: start;
    scroll-margin: 20px;
`;

export const Logo = styled(NextImage)`
    opacity: 0.5;
    filter: grayscale(100%);

    transition: all var(--animation-duration-default) ease;
`;

export const Anchor = styled.a`
    display: inline-flex;
    width: 60px;
    aspect-ratio: 1 / 1;

    @media (any-hover: hover) {
        :hover ${Logo} {
            opacity: 1;
            filter: grayscale(0%);
        }
    }

    ${media.mdUp} {
        width: 50px;
    }

    ${media.lgUp} {
        width: 80px;
    }
`;

export const LogoSkeleton = styled.div`
    ${skeleton()}

    width: 60px;
    aspect-ratio: 1 / 1;

    ${media.mdUp} {
        width: 50px;
    }

    ${media.lgUp} {
        width: 80px;
    }
`;
