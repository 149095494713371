import PropTypes from "prop-types";
import { useRouter } from "next/router";
import { FormProvider, useForm } from "react-hook-form";

import { generateQueryString } from "helpers/utils";

import QuickSearch from "components/shared/quickSearch/QuickSearch";

import { Container } from "./Finder.styled";

function Finder({ privateSpaOnly }) {
    const router = useRouter();
    const methods = useForm({
        mode: "onSubmit"
    });

    const onSubmit = ({ q }) => {
        const params = { q };

        if (privateSpaOnly) {
            params.private = true;
        }

        const queryString = generateQueryString(params);
        router.push(`/resorts/?${queryString}`);
    };

    return (
        <Container isPrivate={privateSpaOnly}>
            <FormProvider {...methods}>
                <form
                    method="GET"
                    action="/resorts/"
                    onSubmit={methods.handleSubmit(onSubmit)}
                >
                    <QuickSearch
                        name="q"
                        label={
                            privateSpaOnly
                                ? "Privé locaties in de buurt"
                                : "Locaties in de buurt"
                        }
                        geoLocationPosition="top"
                        searchForMerchants={
                            privateSpaOnly ? "privateSpa" : "all"
                        }
                    />
                </form>
            </FormProvider>
        </Container>
    );
}

Finder.propTypes = {
    privateSpaOnly: PropTypes.bool
};

Finder.defaultProps = {
    privateSpaOnly: false
};

export default Finder;
