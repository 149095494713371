import PropTypes from "prop-types";

const MerchantSliderItem = {
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    score: PropTypes.number,
    private: PropTypes.bool,
    themes: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string
        })
    ),
    address: PropTypes.shape({
        provinceCode: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired
    }).isRequired
};

export const MerchantSliderItemApiPropTypes = PropTypes.shape({
    ...MerchantSliderItem,
    id: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            alt: PropTypes.string.isRequired
        })
    ),
    themes: PropTypes.shape({
        edges: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string
            })
        )
    })
});

export default {
    ...MerchantSliderItem,
    image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired
    })
};
