import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import Link from "next/link";
import { useInView } from "react-intersection-observer";
import { useQuery } from "@apollo/client/react/hooks";

import MerchantLogoPropTypes from "types/MerchantLogo.propTypes";
import { generateMerchantSlug } from "helpers/merchant";
import { GetMerchantLogos } from "lib/api/cc/queries/merchants.gql";

import { LogoList, ListItem, Anchor, Logo, LogoSkeleton } from "./Logos.styled";

const skeletons = Array(8)
    .fill()
    .map(item => <LogoSkeleton key={JSON.stringify(item)} />);

function renderLogos(merchants) {
    if (!merchants || merchants.length === 0) {
        return skeletons;
    }

    return merchants.map(({ address, slug, logo }) => (
        <Link
            href={generateMerchantSlug(
                address?.provinceCode,
                address?.city,
                slug
            )}
            passHref
            prefetch={false}
        >
            <Anchor>
                <Logo
                    src={logo?.url}
                    alt={logo?.alt}
                    width={80}
                    height={80}
                    objectFit="contain"
                />
            </Anchor>
        </Link>
    ));
}

function Logos({ merchants, privateSpaOnly }) {
    const [logos, setLogos] = useState(skeletons);
    const { ref, inView } = useInView({
        triggerOnce: true,
        initialInView: false
    });
    const queryVariables = {
        pageSize: 8
    };

    if (privateSpaOnly) {
        queryVariables.filter = {
            private: true
        };
    }

    useQuery(GetMerchantLogos, {
        variables: queryVariables,
        skip:
            !inView ||
            merchants.length > 0 ||
            !process.env.featureFlags.cmsData,
        onCompleted: ({ merchants: results }) => {
            if (results?.edges?.length > 0) {
                setLogos(renderLogos(results.edges));
            }
        }
    });

    useMemo(() => {
        if (merchants.length > 0) {
            setLogos(renderLogos(merchants.slice(0, 8)));
        }
    }, [merchants]);

    return (
        <LogoList inView={inView} ref={ref}>
            {logos.map((logo, index) => (
                <ListItem key={`finder-logo-${index}`}>{logo}</ListItem>
            ))}
        </LogoList>
    );
}

Logos.propTypes = {
    merchants: PropTypes.arrayOf(MerchantLogoPropTypes),
    privateSpaOnly: PropTypes.bool
};

Logos.defaultProps = {
    merchants: [],
    privateSpaOnly: false
};

export default Logos;
