import PropTypes from "prop-types";
import Link from "next/link";
import Image from "next/image";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";

import MerchantLogoPropTypes from "types/MerchantLogo.propTypes";

import Finder from "./finder/Finder";
import Logos from "./logos/Logos";

import {
    Wrapper,
    ImageContainer,
    Intro,
    Title,
    Description,
    StyledButton,
    SearchRow,
    IntroRow
} from "./Locations.styled";

function ResortsButton(link, isPrivate, isMobile = false) {
    const { url, label } = link;

    return (
        <Link href={url ?? "/resorts/"} passHref prefetch={false}>
            <StyledButton element="a" variant="outlined" isMobile={isMobile}>
                {label ?? "Alle locaties bekijken"}
            </StyledButton>
        </Link>
    );
}

function Locations({ title, description, image, link, isPrivate, merchants }) {
    const { mobileView } = useMediaQueryContext();

    return (
        <Wrapper forwardedAs="section">
            <IntroRow>
                <Intro isPrivate={isPrivate}>
                    <Title>{title}</Title>
                    <Description>{description}</Description>
                    {!mobileView && ResortsButton(link, isPrivate)}
                </Intro>
                <ImageContainer isPrivate={isPrivate}>
                    {isPrivate ? (
                        <Image
                            src="/images/private-sauna/private-sauna-search-image.jpg"
                            alt="prive sauna sfeer afbeelding"
                            layout="fill"
                            objectFit="cover"
                            quality={75}
                        />
                    ) : (
                        <Image
                            src={image?.url ?? "/images/locations-desktop.jpg"}
                            alt={image?.alt ?? "locaties sfeer afbeelding"}
                            width={680}
                            height={310}
                            layout="responsive"
                            quality={75}
                        />
                    )}
                </ImageContainer>
            </IntroRow>
            <SearchRow isPrivate={isPrivate}>
                <Finder isPrivate={isPrivate} />
                {mobileView && ResortsButton(link, isPrivate, true)}
                {!isPrivate && <Logos merchants={merchants} />}
            </SearchRow>
        </Wrapper>
    );
}

Locations.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired
    }),
    link: PropTypes.shape({
        url: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
    }),
    isPrivate: PropTypes.bool,
    merchants: PropTypes.arrayOf(MerchantLogoPropTypes)
};

Locations.defaultProps = {
    title: "Locaties",
    description:
        "Onze Sauna & Wellness Cadeaubon is te besteden bij meer dan 210 sauna’s, thermen en wellness centra. Zo is er altijd een bij jou in de buurt.",
    image: {
        url: "/images/locations-desktop.jpg",
        alt: "locaties sfeer afbeelding"
    },
    link: {
        url: "/resorts/",
        label: "Alle locaties bekijken"
    },
    isPrivate: false,
    merchants: []
};

export default Locations;
