import styled from "styled-components";

import media from "constants/media";
import colors from "constants/colors";
import { hexToRgba } from "helpers/utils";

import ContentRenderer from "components/ui/contentRenderer/ContentRenderer";

export const List = styled.ol`
    display: flex;
    flex-direction: column;
    gap: 35px 0;
    margin: 0;
    padding: 0;

    counter-reset: step;

    ${media.mobileHide}

    ${media.mdUp} {
        gap: 30px 0;
    }

    ${media.xlUp} {
        gap: 45px 0;
    }
`;

export const MobileSlider = styled.div`
    ${media.mobileOnly}

    counter-reset: step;

    .slider-slide {
        background: var(--color-white);
        border-radius: var(--border-radius-default);
        box-shadow: 0 5px 24px 0 ${hexToRgba(colors.peach400, 0.27)};
    }
`;

export const MobileStep = styled.div`
    padding: 20px 20px 25px;

    counter-increment: step;

    ::before {
        display: block;
        margin: 0 0 5px;

        font-weight: var(--font-weight-extra-light);
        font-size: 40px;
        line-height: 50px;
        text-align: center;

        content: counter(step);
    }
`;

export const Step = styled.li`
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 0 20px;
    justify-items: flex-start;
    margin: 0;
    padding: 0 0 0 40px;

    background-color: var(--color-white);

    counter-increment: step;

    ::before {
        position: absolute;
        top: 0;
        left: 0;

        min-width: 20px;

        font-weight: var(--font-weight-extra-light);
        font-size: 45px;
        line-height: 45px;
        text-align: right;

        content: counter(step);
    }

    ${media.lgUp} {
        padding: 0 0 0 50px;

        ::before {
            min-width: 30px;

            font-size: 60px;
            line-height: 60px;
        }
    }
`;

export const Title = styled.h3`
    margin: 0 0 10px;

    font-size: 18px;
    line-height: 25px;
    text-align: center;

    ${media.mdUp} {
        margin: 0 0 5px;

        text-align: left;
    }

    ${media.lgUp} {
        font-size: 20px;
        line-height: 25px;
    }
`;

export const Text = styled(ContentRenderer)`
    text-align: center;

    p {
        margin: 0;
    }

    ${media.mdUp} {
        text-align: left;
    }
`;
