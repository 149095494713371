import PropTypes from "prop-types";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";
import { resolveApiData } from "helpers/page";

import ClampedText from "components/ui/clampedText/ClampedText";
import DoubleCard from "components/shared/doubleCard/DoubleCard";

import {
    Container,
    Content,
    Title,
    Text,
    ImageContainer
} from "./SeoAndDoubler.styled";

function SeoAndDoubler({ title, description, doubler, doublerContent }) {
    const { mobileView } = useMediaQueryContext();

    return (
        <Container forwardedAs="section">
            {title && description && (
                <Content>
                    {mobileView ? (
                        <ClampedText
                            title={title}
                            text={description}
                            linesVisible={5}
                        />
                    ) : (
                        <>
                            <Title>{title}</Title>
                            <Text>{description}</Text>
                        </>
                    )}
                </Content>
            )}
            {doubler && (
                <ImageContainer>
                    <DoubleCard
                        title={doublerContent?.title}
                        description={doublerContent?.description}
                        link={resolveApiData(doublerContent, "link")}
                        images={doublerContent?.images}
                    />
                </ImageContainer>
            )}
        </Container>
    );
}
SeoAndDoubler.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    doubler: PropTypes.bool,
    doublerContent: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        link: PropTypes.shape({
            url: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired
        }),
        images: PropTypes.arrayOf(
            PropTypes.shape({
                url: PropTypes.string.isRequired,
                alt: PropTypes.string.isRequired
            })
        )
    })
};

SeoAndDoubler.defaultProps = {
    title: "Je Sauna & Wellness bon besteden",
    description:
        "De Nationale Sauna & Wellness Cadeaukaart kan je bij 85+ prachtige locaties door heel Nederland besteden. Zo hoef je nooit ver te reizen voor een bezoek aan een sauna of wellness resort. Maar het kan ook dat je met jouw saunabon juist een nieuwe sauna wilt ontdekken. Zo kan je voor een ontspannen dagje de Thermen Soesterberg bezoeken. In deze prachtige grote sauna kom je weer helemaal tot rust in een natuurlijke omgeving. Maar je bent ook verzekerd van nieuwe energie na een dag in Thermen Bussloo. Thermen Bussloo is prachtig vormgegeven en heeft ontzettend veel sauna's, baden, massages en beautybehandelingen waar je gebruik van kunt maken. Een bezoek bij Fort Resort Beemster zal je niet snel vergeten: dit oude fort is omgebouwd tot een resort met geweldige faciliteiten. Kortom, de saunabon van Sauna & Wellness Cadeaukaart heeft meerdere sauna’s die zeker aan je verwachtingen zullen voldoen!",
    doubler: true,
    doublerContent: {
        title: "Verdubbel de waarde van je kaart",
        description:
            "Maak kans op een verdubbeling van het bedrag op je cadeaukaart. Vul meteen je kaartnummer in en doe mee!",
        link: {
            url: "/verdubbelaar/",
            label: "Ik doe mee!"
        },
        images: [
            {
                url: "/images/doubler.png",
                alt: "Verdubbel de waarde van je kaart"
            },
            {
                url: "/images/doubler-large.png",
                alt: "Verdubbel de waarde van je kaart"
            }
        ]
    }
};

export default SeoAndDoubler;
