import styled from "styled-components";

import media from "constants/media";

export const Title = styled.h2`
    margin: 0 auto 20px;

    font-size: 26px;
    line-height: 35px;

    ${media.mdUp} {
        font-size: 30px;
        line-height: 40px;
    }
`;

export const Text = styled.div`
    max-height: ${({ lines }) => 20 * lines + 40}px;

    margin: 0 auto;
    overflow: hidden;

    font-size: 14px;
    line-height: 20px;

    mask-image: linear-gradient(
        rgba(0, 0, 0, 1) ${({ isShowingMore }) => (isShowingMore ? 99 : 0)}%,
        rgba(0, 0, 0, 0) 100%
    );
    /* stylelint-disable-next-line */
    -webkit-mask-image: -webkit-linear-gradient(
        rgba(0, 0, 0, 1) ${({ isShowingMore }) => (isShowingMore ? 99 : 0)}%,
        rgba(0, 0, 0, 0) 100%
    );

    transition: all 0.6s ease;

    p {
        margin: 0 0 10px;
    }

    ${media.mdUp} {
        max-height: ${({ lines }) => 30 * lines}px;

        font-size: 16px;
        line-height: 30px;
    }
`;

export const Controls = styled.div`
    position: relative;

    margin-top: 10px;
`;
